class User {
  constructor() {
    this.init();
  }

  init() {
    this.id = Number(localStorage.getItem("gestinsinUserId"));
    this.name = localStorage.getItem("gestinsinName");
    this.email = localStorage.getItem("gestinsinEmail");
    this.token = localStorage.getItem("gestinsinGlobalToken");
    this.conexion = localStorage.getItem("gestinsinConexion");
    this.logo = localStorage.getItem("gestinsinLogo");
    this.icono = localStorage.getItem("gestinsinIcono");
  }

  /**
   *
   * @param data object
   * @param data.name string
   * @param data.email string
   * @param callback function
   */
  authenticated(data, callback) {
    localStorage.setItem("gestinsinUserId", data.usuario.id);
    localStorage.setItem("gestinsinName", data.usuario.name);
    localStorage.setItem("gestinsinEmail", data.usuario.email);
    localStorage.setItem("gestinsinGlobalToken", data.token);
    localStorage.setItem("gestinsinConexion", data.empresa.conexion);
    localStorage.setItem("gestinsinLogo", data.empresa.rutalogo);
    localStorage.setItem("gestinsinIcono", data.usuario.avatar);
    this.init();
    callback();
  }

  /**
   *
   * @return {boolean}
   */
  isLoggedIn() {
    return Boolean(this.loggedIn) === true;
  }

  /**
   * Remove all user's data from local storage
   */
  destroy() {
    localStorage.removeItem("gestinsinUserId");
    localStorage.removeItem("gestinsinName");
    localStorage.removeItem("gestinsinEmail");
    localStorage.removeItem("gestinsinGlobalToken");
    localStorage.removeItem("gestinsinConexion");
    localStorage.removeItem("gestinsinLogo");
    localStorage.removeItem("gestinsinIcono");
  }

  /**
   *
   * @param callback function
   */
  logout(callback) {
    this.destroy();

    callback();
  }
}

export default new User();
