import axios from "axios";

let globalToken = localStorage.getItem("gestinsinGlobalToken");

const axiosInstance = axios.create({
  baseURL: "https://pruebas.gestinsin.com/api/",
  //baseURL: "http://127.0.0.1:8000/api/",
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    Authorization: "Bearer " + globalToken, //;globalToken,
  },
});

export default axiosInstance;
