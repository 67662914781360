const menu = [
  {
    label: "sidebar.gestionSiniestro",
    key: "gestion-siniestro",
  },
  {
    label: "sidebar.buscarSiniestro",
    key: "buscar-siniestro",
  },
  {
    label: "sidebar.mostrarOtsSiniestro",
    key: "motrar-ots-siniestro",
  },
  {
    label: "sidebar.buscarOt",
    key: "buscar-ot",
  },
];

export default menu;
