var menu = [];
if (
  localStorage.getItem("gestinsinConexion") == "mysql_001_l" ||
  localStorage.getItem("gestinsinConexion") == "mysql_044_l"
) {
  menu = [
    /*{
    label: "sidebar.listings",
    key: "lists",
    children: [
      {
        label: "sidebar.lateOrders",
        key: "lists/late-orders",
      },
      {
        label: "sidebar.operatorOrders",
        key: "lists/operartor-orders",
      },
    ],
  },*/
    {
      label: "sidebar.control",
      key: "control-lists",
      children: [
        {
          label: "sidebar.controlMapfre",
          key: "control-lists/mapfre/no-token-mapfre",
        },
        {
          label: "sidebar.controlOTEntrantesHoy",
          key: "control-lists/entrantes",
        },
        {
          label: "sidebar.controlOTEntregadasOperario",
          key: "control-lists/entregadas",
        },
        {
          label: "sidebar.controlOTRetrasadas",
          key: "control-lists/retrasadas",
        },
      ],
    },
  ];
} else {
  menu = [
    {
      label: "sidebar.control",
      key: "control-lists",
      children: [
        {
          label: "sidebar.controlOTEntrantesHoy",
          key: "control-lists/entrantes",
        },
        {
          label: "sidebar.controlOTEntregadasOperario",
          key: "control-lists/entregadas",
        },
        {
          label: "sidebar.controlOTRetrasadas",
          key: "control-lists/retrasadas",
        },
      ],
    },
  ];
}

export default menu;
