import React, { Component } from "react";
import { connect } from "react-redux";

import Icon from "../../components/uielements/icon";
import appActions from "../../../redux/app/actions";
import themeActions from "../../../redux/themeSwitcher/actions";
import { AppHolder, Toolbar, IconButtons, TopbarComponents } from "./style";
import TopbarSearch from "./topbarSearch";
import SecondarySidebar from "../SecondarySidebar";
import TopbarNotification from "./sidebarNotification";
import TopbarUser from "./topbarUser";
import { Link } from "react-router-dom";
const { toggleCollapsed } = appActions;
const { switchActivation } = themeActions;

class Topbar extends Component {
  render() {
    const { toggleCollapsed, locale, url, customizedTheme, switchActivation } =
      this.props;
    const propsTopbar = { locale, url };
    return (
      <AppHolder style={{ background: customizedTheme.backgroundColor }}>
        <Toolbar
          style={{
            paddingLeft: "30px",
            minHeight: "64px",
            background: customizedTheme.topbarTheme,
          }}
        >
          <IconButtons
            id="topbarCollapsed"
            aria-label="open drawer"
            onClick={toggleCollapsed}
            className="right"
          >
            <Icon>menu</Icon>
          </IconButtons>
          <Link
            to={{
              pathname: "/dashboard/gestion",
            }}
            onClick={this.props.gestion}
            style={{
              display: "flex",
              color: "#fff",
              borderWidth: 1,
              borderColor: "rgba(0,0,0,0.2)",
              alignItems: "center",
              justifyContent: "center",
              width: 55,
              height: 55,
              backgroundColor: "#ff9900",
              borderRadius: 100,
              marginLeft: "400px",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Gestión
          </Link>
          <Link
            to={{}}
            style={{
              display: "flex",
              color: "#fff",
              borderWidth: 1,
              borderColor: "rgba(0,0,0,0.2)",
              alignItems: "center",
              justifyContent: "center",
              width: 55,
              height: 55,
              backgroundColor: "#ff9900",
              borderRadius: 100,
              marginLeft: "25px",
              textDecoration: "none",
              fontWeight: "bold",
            }}
            onClick={this.props.listados}
          >
            Listados
          </Link>
          <TopbarComponents>
            <ul className="topbarItems">
              <li className="topbarSearch">
                <TopbarSearch {...propsTopbar} />
              </li>

              {/* <li className="topbarNotification">
                <TopbarNotification {...propsTopbar} />
              </li> */}

              <li className="topbarNotification">
                <div>
                  <Icon
                    onClick={() => switchActivation("notification")}
                    style={{ matginTop: 5 }}
                  >
                    widgets
                  </Icon>
                  <SecondarySidebar
                    InnerComponent={TopbarNotification}
                    currentActiveKey="notification"
                    {...propsTopbar}
                  />
                </div>
              </li>

              <li className="topbarUser">
                <TopbarUser {...propsTopbar} />
              </li>
            </ul>
          </TopbarComponents>
        </Toolbar>
      </AppHolder>
    );
  }
}

export default connect(
  (state) => ({
    ...state.App,
    locale: state.LanguageSwitcher.language.locale,
    customizedTheme: state.ThemeSwitcher.topbarTheme,
  }),
  { toggleCollapsed, switchActivation }
)(Topbar);
