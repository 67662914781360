import axios from "../utils/axios";
import errorHandler from "../utils/axiosErrorHandler";

export async function login(data) {
  try {
    let res = await axios.post("login", data);
    return res.data;
  } catch (error) {
    throw errorHandler(error);
  }
}

export async function logout() {
  try {
    let res = await axios.post("logout");
    return res.data;
  } catch (error) {
    throw errorHandler(error);
  }
}
/*
export async function changePassword(data, id) {
  try {
    data.user_id = id;
    let res = await axios.post("changepassword", data);
    return res.data;
  } catch (error) {
    throw errorHandler(error);
  }
}*/
