import { getDefaultPath } from "../../../helpers/urlSync";
import siniestros from "../../SideBarExt/Gestion/siniestros";
import basicos from "../../SideBarExt/Listados/basicos";

let menu = [
  {
    label: "sidebar.settings",
    key: "settings",
    children: [
      {
        label: "sidebar.client",
        key: "settings/client",
      },
      {
        label: "sidebar.companies",
        key: "settings/companies",
      },
      {
        label: "sidebar.rates",
        key: "settings/rates",
      },
      {
        label: "sidebar.scales",
        key: "settings/scales",
      },
    ],
  },
  { label: "sidebar.upload", key: "upload" },
  {
    label: "sidebar.orders",
    key: "orders",
    children: [
      {
        label: "sidebar.active",
        key: "orders/active",
      },
      {
        label: "sidebar.archive",
        key: "orders/archive",
      },
    ],
  },
  {
    label: "sidebar.reports",
    key: "reports",
    children: [
      {
        label: "sidebar.averageTimes",
        key: "reports/average-times",
      },
      {
        label: "sidebar.averageCosts",
        key: "reports/average-costs",
      },
    ],
  },
  {
    label: "sidebar.graphics",
    key: "graphics",
    children: [
      {
        label: "sidebar.byCompany",
        //leftIcon: "map",
        key: "graphics/by-company",
      },
      {
        label: "sidebar.averageTimes",
        key: "graphics/average-time",
      },
    ],
  },
];

const getBreadcrumbOption = () => {
  const preKeys = getDefaultPath();
  let parent, activeChildren;
  menu.forEach((option) => {
    if (preKeys[option.key]) {
      parent = option;
      (option.children || []).forEach((child) => {
        if (preKeys[child.key]) {
          activeChildren = child;
        }
      });
    }
  });
  return { parent, activeChildren };
};

const getSideBarGestion = () => {
  menu = [];
  menu = menu.concat(siniestros);
  return menu;
};

const getSideBarListados = () => {
  menu = [];
  menu = menu.concat(basicos);
  return menu;
};

const menuInit = () => {
  menu = [];
  menu = [
    {
      label: "sidebar.settings",
      key: "settings",
      children: [
        {
          label: "sidebar.client",
          key: "settings/client",
        },
        {
          label: "sidebar.companies",
          key: "settings/companies",
        },
        {
          label: "sidebar.rates",
          key: "settings/rates",
        },
        {
          label: "sidebar.scales",
          key: "settings/scales",
        },
      ],
    },
    { label: "sidebar.upload", key: "upload" },
    {
      label: "sidebar.orders",
      key: "orders",
      children: [
        {
          label: "sidebar.active",
          key: "orders/active",
        },
        {
          label: "sidebar.archive",
          key: "orders/archive",
        },
      ],
    },
    {
      label: "sidebar.reports",
      key: "reports",
      children: [
        {
          label: "sidebar.averageTimes",
          key: "reports/average-times",
        },
        {
          label: "sidebar.averageCosts",
          key: "reports/average-costs",
        },
      ],
    },
    {
      label: "sidebar.graphics",
      key: "graphics",
      children: [
        {
          label: "sidebar.byCompany",
          //leftIcon: "map",
          key: "graphics/by-company",
        },
        {
          label: "sidebar.averageTimes",
          key: "graphics/average-time",
        },
      ],
    },
  ];
  return menu;
};

export default menu;
export { getBreadcrumbOption, getSideBarGestion, getSideBarListados, menuInit };
