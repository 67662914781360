import asyncComponent from "../helpers/AsyncFunc";

const routes = [
  /*{
    path: "blank-page",
    component: asyncComponent(() => import("./containers/blankPage")),
  },*/
  {
    path: "settings/client",
    component: asyncComponent(() => import("./containers/settings/clients")),
  },
  {
    path: "settings/companies",
    component: asyncComponent(() => import("./containers/settings/companies")),
  },
  {
    path: "settings/rates",
    component: asyncComponent(() => import("./containers/settings/rates")),
  },
  {
    path: "settings/scales",
    component: asyncComponent(() => import("./containers/settings/scales")),
  },
  {
    path: "upload",
    component: asyncComponent(() => import("./containers/subir/index")),
  },
  {
    path: "orders/active",
    component: asyncComponent(() => import("./containers/orders/actived")),
  },
  {
    path: "orders/archive",
    component: asyncComponent(() => import("./containers/orders/archived")),
  },
  {
    path: "lists/late-orders",
    component: asyncComponent(() => import("./containers/lists/late-orders")),
  },
  {
    path: "lists/operartor-orders",
    component: asyncComponent(() =>
      import("./containers/lists/operator-orders")
    ),
  },
  {
    path: "reports/average-times",
    component: asyncComponent(() =>
      import("./containers/reports/average-times")
    ),
  },
  {
    path: "reports/average-costs",
    component: asyncComponent(() =>
      import("./containers/reports/average-costs")
    ),
  },
  {
    path: "graphics/by-company",
    component: asyncComponent(() =>
      import("./containers/graphics/by-companies")
    ),
  },
  {
    path: "graphics/average-time",
    component: asyncComponent(() =>
      import("./containers/graphics/average-times")
    ),
  },
  {
    path: "control-lists/mapfre/no-token-mapfre",
    component: asyncComponent(() =>
      import("./containers/control-lists/mapfre/mapfre-no-token")
    ),
  },
  {
    path: "control-lists/entrantes",
    component: asyncComponent(() =>
      import("./containers/control-lists/ot-entrantes-hoy")
    ),
  },
  {
    path: "control-lists/entregadas",
    component: asyncComponent(() =>
      import("./containers/control-lists/ot-entregadas-operario")
    ),
  },
  {
    path: "control-lists/retrasadas",
    component: asyncComponent(() =>
      import("./containers/control-lists/ot-retrasadas")
    ),
  },
  {
    path: "gestion",
    component: asyncComponent(() => import("./containers/gestion/index")),
  },
];
export default routes;
